<template>
  <div class="site-wrapper">
    <div class="a-main a-account">
      <div class="container">
        <div class="a-main__wrap a-account__wrap">
          <div class="a-main__back">
            <router-link :to="{ path: 'home' }">
              <svg
                class="back"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 240.823 240.823"
              >
                <path
                  d="M57.633 129.007L165.93 237.268c4.752 4.74 12.451 4.74 17.215 0 4.752-4.74 4.752-12.439 0-17.179l-99.707-99.671 99.695-99.671c4.752-4.74 4.752-12.439 0-17.191-4.752-4.74-12.463-4.74-17.215 0L57.621 111.816c-4.679 4.691-4.679 12.511.012 17.191z"
                />
              </svg>
            </router-link>
          </div>
          <div class="a-main__details">
            <legend><h2 class="account-setting">Code Garmin</h2></legend>
            <br />
            <div class="data-chart__toggler mb__22 d-flex justify-content-end">
              <div
                data-slide="data-chart__content--one"
                class="index-tab index-tab--two"
                v-bind:class="[this.view == 'PulseOx' ? 'nav-active' : '']"
                @click="onPulseOxClick()"
              >
                PulseOX
              </div>
              <div
                data-slide="data-chart__content--one"
                v-bind:class="[this.view == 'Sleep' ? 'nav-active' : '']"
                class="index-tab index-tab--one"
                @click="onSleepClick()"
              >
                Sleep
              </div>
            </div>
            <div
              class="data-chart__toggler mb__22 d-flex justify-content-start date-nav"
            >
              <div
                class="index-tab index-tab--two"
                v-bind:class="[this.nav == 'prev' ? 'nav-active' : '']"
                data-slide="data-chart__content--two"
                @click="onNavClick('prev')"
              >
                <!-- <BackArrowSvg /> -->
                Prev
              </div>
              <div
                class="index-tab index-tab--two"
                v-bind:class="[this.nav == 'today' ? 'nav-active' : '']"
                data-slide="data-chart__content--two"
                @click="onNavClick('today')"
              >
                Today
              </div>
              <div
                class="index-tab index-tab--two"
                v-bind:class="[this.nav == 'next' ? 'nav-active' : '']"
                data-slide="data-chart__content--two"
                @click="onNavClick('next')"
              >
                Next
              </div>
            </div>
            <div
              v-if="this.view == 'Sleep' && Object.keys(this.sleeps).length > 0"
              class="sleep-summary"
            >
              <p class="deep mr-1">
                Deep: {{ getHourMinute(sleeps.deepSleepDurationInSeconds) }} |
              </p>
              <p class="light mr-1">
                Light: {{ getHourMinute(sleeps.lightSleepDurationInSeconds) }} |
              </p>
              <p class="rem mr-1">
                REM: {{ getHourMinute(sleeps.remSleepInSeconds) }}
              </p>
            </div>
            <div v-show="this.view === 'PulseOx'" class="a-main__detail-list">
              <table v-if="Object.keys(this.pulses).length > 0" class="table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">SpO2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, i) in this.spo2Values" :key="i">
                    <th scope="row">
                      {{ getDateMonthYear(pulses.calendarDate) }}
                    </th>
                    <td>{{ getDateAmPm(pulses.calendarDate, p.x) }}</td>
                    <td>{{ p.y }} %</td>
                  </tr>
                </tbody>
              </table>
              <p v-else-if="loading === false && this.garminStatus == true">
                No pulse-ox data for {{ getDateMonthYear(this.selectedDate) }} .
              </p>
            </div>
            <div v-show="this.view === 'Sleep'" class="a-main__detail-list">
              <table v-if="Object.keys(this.sleeps).length > 0" class="table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">SpO2</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(p, i) in sleeps.timeOffsetSleepSpo2" :key="i">
                    <th scope="row">
                      {{ getSleepDateMonthYear(sleeps.startTimeInSeconds, i) }}
                    </th>
                    <td>{{ getSleepDateAmPm(sleeps.startTimeInSeconds, i) }}</td>
                    <td>{{ p }}</td>
                  </tr>
                </tbody>
              </table>
              <p v-else-if="loading === false && this.garminStatus == true">
                No sleeps data for {{ getDateMonthYear(this.selectedDate) }} .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import {
  calendarDateFormat,
  hourMinSec,
  AmPm,
  garminPromptMixin,
} from "@/mixins/appMixins";
export default {
  name: "TestData",
  mixins: [calendarDateFormat, hourMinSec, AmPm, garminPromptMixin],

  data() {
    return {
      pulses: {},
      spo2Values: [],
      sleeps: {},
      view: "PulseOx",
      selectedDate: moment(),
      nav: "today",
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    ...mapState("garmin", ["garminStatus"]),
    ...mapState(["loading"]),
  },

  async mounted() {
    await this.checkGarminStatus();
    if (this.garminStatus) {
      try {
        this.setLoading(true);
        let res = await this.pullPulseOx(
          this.getDateMonthYear(this.selectedDate)
        );
        if (res.data) {
          this.pulses = res.data;
          this.spo2Values = res.spo2;
        } else {
          this.pulses = {};
          this.spo2Values = [];
        }
      } catch (e) {
        this.$toaster.error("Error fetching pulse data");
        this.setLoading(false);
      }
      try {
        let res = await this.pullSleep(
          this.getDateMonthYear(this.selectedDate)
        );
        if (res.data) {
          this.sleeps = res.data;
        } else {
          this.sleeps = {};
          this.$toaster.error(res.msg);
        }
        this.setLoading(false);
      } catch (e) {
        this.$toaster.error("Error fetching sleep data");
        this.setLoading(false);
      }
    } else {
      this.promptGarminConnect();
    }
  },

  methods: {
    ...mapActions("garmin", ["pullPulseOx", "pullSleep", "checkGarminStatus"]),
    ...mapActions(["setLoading"]),
    onNavClick($type) {
      if ($type == "prev") {
        this.selectedDate = this.selectedDate.subtract(1, "days");
      } else if ($type == "next") {
        this.selectedDate = this.selectedDate.add(1, "days");
      } else {
        this.selectedDate = moment();
      }
      this.nav = $type;
      if (this.view == "PulseOx") {
        this.pullPulseOxData();
      } else {
        this.pullSleepData();
      }
    },

    onSleepClick() {
      if (this.garminStatus) {
        this.view = "Sleep";
        this.pullSleepData();
      } else {
        this.promptGarminConnect();
      }
    },
    onPulseOxClick() {
      if (this.garminStatus) {
        this.view = "PulseOx";
        this.pullPulseOxData();
      } else {
        this.promptGarminConnect();
      }
    },

    async pullPulseOxData() {
      try {
        this.setLoading(true);
        let res = await this.pullPulseOx(
          this.getDateMonthYear(this.selectedDate)
        );
        if (res.data) {
          this.pulses = res.data;
          this.spo2Values = res.spo2;
        } else {
          this.pulses = {};
          this.spo2 = [];
          this.$toaster.error(res.msg);
        }
        this.setLoading(false);
      } catch (e) {
        this.$toaster.error("Error fetching pulse data");
        this.setLoading("error");
      }
    },
    async pullSleepData() {
      try {
        this.setLoading(true);
        let res = await this.pullSleep(
          this.getDateMonthYear(this.selectedDate)
        );
        if (res.data) {
          this.sleeps = res.data;
        } else {
          this.sleeps = {};
          this.$toaster.error(res.msg);
        }
        this.setLoading(false);
      } catch (e) {
        this.$toaster.error("Error fetching sleep data");
        this.setLoading("error");
      }
    },
  },
};
</script>

<style scoped>
.a-main__back {
  text-align: left;
}
.account-setting {
  text-align: left;
}
.garmin-connect {
  cursor: pointer;
}

.table thead th {
  border-bottom: 1px solid #dee2e6;
}

.table td,
.table th {
  border-top: 1px solid #dee2e6;
}
.sleep-summary {
  display: flex;
  font-size: 18px;
  font-style: bold;
  font-family: sans-serif;
}
.deep {
  color: #150556;
}
.light {
  color: #391dc7;
}
.rem {
  color: #e71bd6;
}
.date-nav {
  margin-top: -51px;
}
.nav-active {
  color: white;
}

.index-tab {
  padding: 4px 8px;
  font-size: 15px;
  min-width: 60px;
  background-color: hsl(230deg 55% 38% / 70%);
}

.a-main__detail-list tbody {
  font-size: 15px;
}

.sleep-summary p {
  font-size: 16px;
}

@media screen and (max-width: 360px) {
  .index-tab {
    padding: 4px 8px;
    font-size: 13px;
    min-width: 50px;
  }

  .a-main__detail-list tbody {
    font-size: 14px;
  }

  .data-chart__toggler.date-nav {
    margin-top: -48px !important;
  }
}
</style>
